import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import { graphql } from 'gatsby'
import CTALink from '../components/cta-link'

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`

const AboutPage = ({ data, location }) => (
	<Layout location={location}>
		<Grid>
			<h1>Erfolgsprojekte</h1>
			<p>Hier finden Sie unsere Erfolgsprojekte als Broschüre zum Download</p>
			<CTALink href={data.file.publicURL} target="_blank">Download</CTALink>
		</Grid>
	</Layout>
)

export const pdfQuery = graphql`
query MyQuery {
  file(relativeDirectory: {eq: "assets"}, name: {eq: "erfolgsprojekte"}, extension: {eq: "pdf"}) {
    publicURL
  }
}
`

export default AboutPage
